/** @jsxImportSource @emotion/react */

import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'

import { HeadingNew } from '../../components/HeadingNew'
import { colors } from '../../styles/theme'

export const AdTestPage = () => {
  return (
    <Layout backgroundColor={colors.green600} hideNavbar>
      <Flex column grow>
        <PageContent>
          <HeadingNew color={colors.white} level={1}>
            {'Ad Test'}
          </HeadingNew>
        </PageContent>
      </Flex>
    </Layout>
  )
}
