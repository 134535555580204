/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import 'quill/dist/quill.snow.css'
import { useEffect, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Chip } from '../../components/Chip'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { HideAfterDelay } from '../../components/HideAfterDelay'
import { Loader } from '../../components/Loader'
import { SelectInput } from '../../components/SelectInput'
import { Text } from '../../components/Text'
import { TextArea, TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Modal, ModalTrigger } from '../../components/modal/Modal'
import {
  Locale,
  LocaleString,
  localeToFlag,
  locales,
} from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import { useForm } from '../../utils/useForm'
import { ChallengeNavItem } from '../Challenge/ChallengeGroupPage'
import { useClubsQuery } from '../Clubs/queries'
import { useLibrarySearchQuery } from '../Search/queries'
import { useTranslateToFromQuery } from '../Translate/queries'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'
import {
  useSendPushNotificationsMutation,
  useUsersWithPushNotificationTokensQuery,
} from './queries'

const isNotEmpty = (str?: string | null) => {
  return str !== undefined && str !== null && str.trim().length > 0
}

enum PushTargetMode {
  all = 'All users',
  clubMembers = 'Club members',
  seriesSubscribers = 'Series subscribers',
}

const Content = () => {
  const translateQuery = useTranslateToFromQuery()

  const [loading, setLoading] = useState(false)

  const [targetMode, setTargetMode] = useState<PushTargetMode>(
    PushTargetMode.all
  )

  const [clubs, setClubs] = useState<
    { id: string; name: string; verified: boolean }[]
  >([])
  const [verifiedClubsOnly, setVerifiedClubsOnly] = useState(true)

  const clubIds = clubs.map((c) => c.id)
  const [leagues, setLeagues] = useState<{ id: string; title: string }[]>([])

  const leagueIds = leagues.map((l) => l.id)

  const [clubQuery, setClubQuery] = useState('')
  const debouncedClubQuery = useDebouncedString(clubQuery, 300, {
    minLength: 3,
  })

  const clubsQuery = useClubsQuery({
    query: debouncedClubQuery,
    limit: debouncedClubQuery.length > 0 ? undefined : 50,
    skip: debouncedClubQuery.length < 3,
    verifiedOnly: verifiedClubsOnly,
    skipCacheRead: true,
  })

  const searchResultClubs = clubsQuery.clubs ?? []

  const [leagueQuery, setLeagueQuery] = useState('')
  const debouncedLeagueQuery = useDebouncedString(leagueQuery, 300, {
    minLength: 3,
  })

  const leaguesQuery = useLibrarySearchQuery(debouncedLeagueQuery)

  const searchResultLeagues = leaguesQuery.data?.librarySearch.leagues ?? []

  const resetTargeting = () => {
    setClubs([])
    setClubQuery('')
    setLeagues([])
    setLeagueQuery('')
    setVerifiedClubsOnly(true)
  }

  useEffect(() => {
    resetTargeting()
  }, [targetMode])

  const [sendPushNotificationsMutation, sendPushNotificationsResult] =
    useSendPushNotificationsMutation()

  const [titles, setTitles] = useState<LocaleString>({
    en: '',
  })

  const [bodies, setBodies] = useState<LocaleString>({
    en: '',
  })

  const [oldLocale, setOldLocale] = useState<Locale>(Locale.en)
  const [locale, setLocale] = useState<Locale>(Locale.en)

  const [completeLocales, setCompleteLocales] = useState<Locale[]>([])

  const [translateFromLocale, setTranslateFromLocale] = useState<
    Locale | undefined
  >()

  const numUsersTokensQuery = useUsersWithPushNotificationTokensQuery(
    completeLocales,
    clubIds,
    leagueIds
  )

  const selectedLocaleIsComplete = completeLocales.includes(locale)

  const prodFrontendDomain = 'https://fcquiz.app'

  const form = useForm({
    initialValues: {
      title: titles[locale] ?? '',
      body: bodies[locale] ?? '',
      url: '',
    },
    validate: {
      url: (url) => {
        if (url && !url.startsWith(prodFrontendDomain)) {
          return `Url must start with ${prodFrontendDomain}`
        }
      },
    },

    onSubmit: async () => {
      if (sendPushNotificationsResult.loading) {
        return
      }

      const submitUrl =
        form.values.url.trim().length > 0 ? form.values.url : undefined

      sendPushNotificationsMutation({
        variables: {
          input: {
            title: titles,
            body: bodies,
            url: submitUrl,
            clubIds,
            leagueIds,
          },
        },
      }).then(() => {
        setTitles({ en: '' })
        setBodies({ en: '' })
        setLocale(Locale.en)
        setOldLocale(Locale.en)
      })
    },
  })

  useEffect(() => {
    if (oldLocale !== locale) {
      form.values.title = titles[locale] ?? ''
      form.values.body = bodies[locale] ?? ''
      setOldLocale(locale)
      return
    }
    if (titles[locale] !== form.values.title) {
      setTitles({ ...titles, [locale]: form.values.title })
    }
    if (bodies[locale] !== form.values.body) {
      setBodies({ ...bodies, [locale]: form.values.body })
    }
  }, [form])

  useEffect(() => {
    const completeLocales = locales.filter(
      (it) => isNotEmpty(titles[it]) && isNotEmpty(bodies[it])
    ) as Locale[]
    setCompleteLocales(completeLocales)
  }, [titles, bodies])

  useEffect(() => {
    if (completeLocales.length === 1) {
      setTranslateFromLocale(completeLocales[0])
      return
    }

    if (completeLocales.length === 0) {
      setTranslateFromLocale(undefined)
      return
    }
  }, [completeLocales])

  const formHasUrlError = !!form.fieldErrors.url

  const translate = async () => {
    if (!translateFromLocale) {
      alert('Please select a locale to translate from.')
      return
    }

    if (locale === translateFromLocale) {
      alert('Cannot translate to the same language.')
      return
    }

    const body = bodies[translateFromLocale]
    const title = titles[translateFromLocale]

    if (!body) {
      alert('Please enter a body to translate.')
      return
    }

    if (!title) {
      alert('Please enter a title to translate.')
      return
    }

    const bodyWords = body.split(' ').length
    if (bodyWords > 100) {
      alert(`This can take up to 3 min. (Words to translate: ${bodyWords})`)
    }

    const result = await translateQuery.refetch({
      input: {
        from: translateFromLocale,
        to: locale,
        texts: [title, body],
      },
    })

    const res = result.data?.translateToFrom ?? []

    if (res.length !== 2) {
      alert('Translation failed')
      return
    }

    setTitles({ ...titles, [locale]: res[0] })
    form.setValue('title', res[0])
    setBodies({ ...bodies, [locale]: res[1] })
    form.setValue('body', res[1])
  }

  return (
    <div
      css={[
        margin.top('large'),
        css`
          background-color: ${colors.grey200};
          padding: 12px;
          border-radius: 8px;
        `,
      ]}
    >
      <Flex column gap="small">
        <TextNew> Targeting: {targetMode}</TextNew>
        <Spacer height={4} />
        <Flex wrap gap={'medium'}>
          <ChallengeNavItem
            title={PushTargetMode.all}
            onClick={() => setTargetMode(PushTargetMode.all)}
            selected={targetMode === PushTargetMode.all}
          />
          <ChallengeNavItem
            title={PushTargetMode.clubMembers}
            onClick={() => setTargetMode(PushTargetMode.clubMembers)}
            selected={targetMode === PushTargetMode.clubMembers}
          />
          <ChallengeNavItem
            title={PushTargetMode.seriesSubscribers}
            onClick={() => setTargetMode(PushTargetMode.seriesSubscribers)}
            selected={targetMode === PushTargetMode.seriesSubscribers}
          />
        </Flex>
      </Flex>
      <Spacer height={16} />

      {targetMode === PushTargetMode.clubMembers && (
        <Flex column gap={'medium'} css={margin.bottom('medium')}>
          <Flex wrap gap={'small'}>
            {clubs.map((club) => {
              return (
                <Chip
                  key={`selected_${club.id}`}
                  label={`${club.name}${club.verified ? ' ✔' : ''}`}
                  backgroundColor={colors.yellow400}
                  color={colors.black}
                  clickable
                  onClick={() => {
                    setClubs(clubs.filter((c) => c.id !== club.id))
                  }}
                />
              )
            })}{' '}
          </Flex>

          <TextInput
            label={'Search for club'}
            onValue={setClubQuery}
            css={css`
              flex: 1;
            `}
          />
          <ToggleSwitch
            value={verifiedClubsOnly}
            onValue={setVerifiedClubsOnly}
            label={'Verified only'}
          />

          <Flex wrap gap={'small'}>
            {searchResultClubs.map((club) => {
              const selected = clubIds.includes(club.id)
              return (
                <Chip
                  clickable
                  key={club.id}
                  label={`${club.name}${club.verified ? ' ✔' : ''}`}
                  backgroundColor={
                    selected ? colors.yellow400 : colors.yellow200
                  }
                  color={colors.black}
                  onClick={() => {
                    if (selected) {
                      setClubs(clubs.filter((c) => c.id !== club.id))
                    } else {
                      setClubs([...clubs, club])
                    }
                  }}
                />
              )
            })}
          </Flex>
        </Flex>
      )}
      {targetMode === PushTargetMode.seriesSubscribers && (
        <Flex column gap={'medium'} css={margin.bottom('medium')}>
          <Flex wrap gap={'small'}>
            {leagues.map((league) => {
              return (
                <Chip
                  key={`selected_${league.id}`}
                  label={league.title}
                  backgroundColor={colors.yellow400}
                  color={colors.black}
                  clickable
                  onClick={() => {
                    setLeagues(leagues.filter((l) => l.id !== league.id))
                  }}
                />
              )
            })}
          </Flex>
          <TextInput label={'Search for series'} onValue={setLeagueQuery} />
          <Flex wrap gap={'small'}>
            {searchResultLeagues.map((league) => {
              const selected = leagueIds.includes(league.id)
              return (
                <Chip
                  clickable
                  key={league.id}
                  label={league.title}
                  backgroundColor={
                    selected ? colors.yellow400 : colors.yellow200
                  }
                  color={colors.black}
                  onClick={() => {
                    if (selected) {
                      setLeagues(leagues.filter((c) => c.id !== league.id))
                    } else {
                      setLeagues([...leagues, league])
                    }
                  }}
                />
              )
            })}
          </Flex>
        </Flex>
      )}
      <Flex
        css={css`
          padding: 8px;
          background: ${colors.yellow100};
          border-radius: 2px;
          border: 1px solid gray;
        `}
      >
        {completeLocales.length === 0 &&
          clubs.length === 0 &&
          leagues.length === 0 && (
            <Text>
              Total users with push notifications:{' '}
              {numUsersTokensQuery.data?.usersWithPushNotificationTokens ?? 0}{' '}
            </Text>
          )}
        {(completeLocales.length > 0 ||
          clubs.length > 0 ||
          leagues.length > 0) && (
          <Text>
            Send push notification to{' '}
            {numUsersTokensQuery.data?.usersWithPushNotificationTokens ?? 0}{' '}
            people
          </Text>
        )}
      </Flex>
      <Spacer height={16} />

      <Flex gap="tiny" wrap>
        {locales.map((it) => {
          const localeIsComplete = titles[it] && bodies[it]
          return (
            <SecondaryButton
              key={it}
              css={[
                css`
                  border: none;
                  border-radius: 8px 8px 0 0;
                  border-bottom: 0;
                  z-index: 2;
                  background-color: ${localeIsComplete
                    ? colors.green200
                    : colors.grey100};
                `,
                it !== locale &&
                  css`
                    background-color: ${localeIsComplete
                      ? colors.green200
                      : colors.grey200};
                  `,
              ]}
              onClick={() => setLocale(it)}
            >
              {localeToFlag[it]}
            </SecondaryButton>
          )
        })}
      </Flex>

      <Flex
        column
        css={css`
          background-color: ${selectedLocaleIsComplete
            ? colors.green200
            : colors.grey100};
          padding: 8px;
        `}
      >
        <TextInput
          label="Title"
          value={form.values.title}
          onValue={(val) => {
            form.setValue('title', val)
          }}
          css={margin.top()}
          error={form.fieldErrors.title}
        />

        <TextArea
          label="Body"
          value={form.values.body}
          onValue={(val) => {
            form.setValue('body', val)
          }}
          css={margin.top()}
          textCss={css`
            height: 200px;
          `}
          error={form.fieldErrors.body}
        />
        <Spacer height={16} />
        <Flex
          horizontal="space-between"
          gap={'large'}
          css={css`
            flex-wrap: wrap;
          `}
        >
          {loading && <Loader />}
          {!loading && (
            <Flex gap={'small'}>
              <SecondaryButton
                onClick={async () => {
                  setLoading(true)
                  await translate()
                  setLoading(false)
                }}
                disabled={completeLocales.length === 0}
              >
                Translate
              </SecondaryButton>
              <SelectInput
                disabled={completeLocales.length === 0}
                label="Translate from"
                value={translateFromLocale}
                onValue={(value) => {
                  const toLocale = Locale[value]
                  setTranslateFromLocale(toLocale)
                }}
              >
                {completeLocales.map((it) => (
                  <option key={it} value={it}>
                    {localeToFlag[it]}
                  </option>
                ))}
              </SelectInput>
            </Flex>
          )}
          <SecondaryButton
            disabled={
              (!bodies[locale] || bodies[locale] === '') &&
              (!titles[locale] || titles[locale] === '')
            }
            onClick={() => {
              const currentLocale = locale

              setTitles((prev) => {
                const newTitles = { ...prev }
                delete newTitles[currentLocale]
                return newTitles
              })

              setBodies((prev) => {
                const newBodies = { ...prev }
                delete newBodies[currentLocale]
                return newBodies
              })

              form.setValue('title', '')
              form.setValue('body', '')
            }}
          >
            Clear language
          </SecondaryButton>
        </Flex>
      </Flex>

      <TextArea
        label="URL"
        value={form.values.url}
        onValue={(val) => form.setValue('url', val)}
        css={margin.top()}
        error={form.fieldErrors.url}
      />

      <ModalTrigger
        button={({ openModal }) => (
          <Flex horizontal="flex-end">
            <PrimaryButton
              onClick={openModal}
              css={margin.top('large')}
              disabled={completeLocales.length === 0 || formHasUrlError}
            >
              Send notifications
            </PrimaryButton>
          </Flex>
        )}
        modal={({ closeModal }) => {
          return (
            <Modal heading="Are you sure?" closeModal={closeModal}>
              <Flex column>
                <Text>
                  Are you sure you want to send push notifications to{' '}
                  {numUsersTokensQuery.data?.usersWithPushNotificationTokens ??
                    0}{' '}
                  people?
                </Text>

                <Spacer height={16} />
                <Flex column gap={'medium'}>
                  {completeLocales.map((it) => {
                    return (
                      <Flex
                        vertical="center"
                        horizontal="space-between"
                        gap={'medium'}
                      >
                        <Flex
                          css={[
                            css`
                              width: 100%;
                              background-color: rgb(172, 165, 161, 0.5);
                              border-radius: 16px;
                              padding: 8px;
                            `,
                          ]}
                          gap="medium"
                        >
                          <Flex vertical="center">
                            <div
                              css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: black;
                                border-radius: 8px;
                                padding: 6px;
                              `}
                            >
                              <Logo size={28} color="white" />
                            </div>
                          </Flex>
                          <Flex column>
                            <Text
                              color="black"
                              css={css`
                                font-family: Helvetica, Arial;
                              `}
                              strong
                            >
                              {titles[it]}
                            </Text>
                            <Text
                              color="black"
                              css={css`
                                font-family: Helvetica, Arial;
                                line-height: 1.21;
                              `}
                            >
                              {bodies[it]}
                            </Text>
                          </Flex>
                        </Flex>
                        <TextNew
                          color={colors.grey400}
                          textAlign="center"
                          css={css`
                            padding: 8px;
                            min-width: 40px;
                          `}
                        >
                          {it}
                        </TextNew>
                      </Flex>
                    )
                  })}
                </Flex>

                <Spacer height={16} />

                {form.values.url && (
                  <>
                    <Spacer height={16} />
                    <TextNew strong>URL</TextNew>
                    <TextNew>{form.values.url}</TextNew>
                    <Spacer height={8} />
                    <TextNew color={colors.grey400}>
                      Note: The URL will be opened when the user clicks the
                      notification.
                    </TextNew>
                  </>
                )}

                <Flex
                  horizontal="flex-end"
                  vertical="center"
                  css={margin.top('large')}
                  gap="medium"
                >
                  <SecondaryButton
                    onClick={closeModal}
                    disabled={sendPushNotificationsResult.loading}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() =>
                      form.submitForm().then(() => {
                        form.setValue('title', '')
                        form.setValue('body', '')
                        form.setValue('url', '')
                        setClubs([])
                        resetTargeting()
                        closeModal()
                      })
                    }
                    loading={sendPushNotificationsResult.loading}
                  >
                    Yes, send!
                  </PrimaryButton>
                </Flex>
              </Flex>
            </Modal>
          )
        }}
      />

      {sendPushNotificationsResult.data && (
        <HideAfterDelay delayMs={5000}>
          <Flex css={margin.top()}>
            <Text strong>Push notifications sent</Text>
          </Flex>
        </HideAfterDelay>
      )}
    </div>
  )
}

export const AdminPushPage = () => {
  const t = useT()

  return (
    <Layout title={`fcQuiz | ${t('Administration')}`}>
      <AdminAccessControlComponent>
        <Header color="green" />

        <PageContent grow>
          <AdminNavbar activePage="push" />

          <Content />
        </PageContent>

        <Footer />
      </AdminAccessControlComponent>
    </Layout>
  )
}
