/** @jsxImportSource @emotion/react */

import dayjs, { Dayjs } from 'dayjs'
import { createContext, useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Header } from '../../components/Header'
import { Heading } from '../../components/Heading'
import { Loader } from '../../components/Loader'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Page } from '../../components/leagueQuiz/Page'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'
import { Locale } from '../../lib/i18n/locale'
import { Category } from '../../lib/types'
import { colors } from '../../styles/theme'
import { useLeaguePageQuery } from '../League/queries'
import {
  GetCareerPathQuizMsg,
  GetQuizMsg,
  QuestionTranslate,
  useGetQuizQuery,
} from '../Translate/queries'
import { AIPrompt } from './AIPrompt'
import { AIVerify } from './AIVerify'
import { Finish } from './Finish'
import { CareerPathQuestion, GenerateQuestion } from './GenerateQuestion'
import { OverviewFinish } from './League/OverviewFinish'
import { PickTemplateModal } from './PickTemplateModal'
import { swapIndex } from './util'

export enum QuizType {
  TEXT = 'text',
  CAREERPATH = 'careerPath',
  COMBINATION = 'combination',
}

enum Step {
  None,
  AIPrompt,
  AIVerify,
  Questions,
  Finish,
}

export type Question = {
  id: string
  question: string
  alternatives: string[]
  timeless: boolean
  aiGenerated: boolean
  referenceUrl: string
  questionIndex: number
  questionLimit: number
  madeByUser: boolean
  leagueSlug: string
}

const FinishContext = createContext<{
  id: string
  setId: (value: string) => void
  quizName: string
  setQuizName: (value: string) => void
  promotionalText: string
  setPromotionalText: (value: string) => void
  startDate: Dayjs | null
  setStartDate: (value: Dayjs | null) => void
  endDate: Dayjs | null
  setEndDate: (value: Dayjs | null) => void
  language: Locale | null
  setLanguage: (value: Locale | null) => void
  noExpirationDate: boolean
  setNoExpirationDate: (value: boolean) => void
  randomizeOrder: boolean
  setRandomizeOrder: (value: boolean) => void
  selectedCategories: Category[]
  setSelectedCategories: (value: Category[]) => void
  load: GetQuizMsg | GetCareerPathQuizMsg | null
  setLoad: (value: GetQuizMsg | GetCareerPathQuizMsg | null) => void
  loadStartState: any
  setLoadStartState: (value: any) => void
  isLiveQuiz: boolean
  setIsLiveQuiz: (value: boolean) => void
  resetState: () => void
}>({
  id: '',
  setId: () => {},
  quizName: '',
  setQuizName: () => {},
  promotionalText: '',
  setPromotionalText: () => {},
  startDate: null,
  setStartDate: () => {},
  endDate: null,
  setEndDate: () => {},
  language: Locale.en,
  setLanguage: () => {},
  noExpirationDate: false,
  setNoExpirationDate: () => {},
  randomizeOrder: false,
  setRandomizeOrder: () => {},
  selectedCategories: [],
  setSelectedCategories: () => {},
  load: null,
  setLoad: () => {},
  loadStartState: null,
  setLoadStartState: () => {},
  isLiveQuiz: false,
  setIsLiveQuiz: () => {},
  resetState: () => {},
})

export const useFinishContext = () => {
  return useContext(FinishContext)
}

export const FinishContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [id, setId] = useState<string>(uuidv4())
  const [quizName, setQuizName] = useState<string>('')
  const [promotionalText, setPromotionalText] = useState<string>('')
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [language, setLanguage] = useState<Locale | null>(null)
  const [noExpirationDate, setNoExpirationDate] = useState<boolean>(false)
  const [randomizeOrder, setRandomizeOrder] = useState<boolean>(false)
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([])
  const [load, setLoad] = useState<any>(null)
  const [loadStartState, setLoadStartState] = useState<any>(null)
  const [isLiveQuiz, setIsLiveQuiz] = useState<boolean>(false)

  const resetState = () => {
    setId(uuidv4())
    setQuizName('')
    setPromotionalText('')
    setStartDate(null)
    setEndDate(null)
    setNoExpirationDate(false)
    setRandomizeOrder(false)
    setSelectedCategories([])
    setLoad(null)
    setLoadStartState(null)
    setIsLiveQuiz(false)
  }

  const value = {
    id,
    setId,
    quizName,
    setQuizName,
    promotionalText,
    setPromotionalText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    language,
    setLanguage,
    noExpirationDate,
    setNoExpirationDate,
    randomizeOrder,
    setRandomizeOrder,
    selectedCategories,
    setSelectedCategories,
    load,
    setLoad,
    loadStartState,
    setLoadStartState,
    isLiveQuiz,
    setIsLiveQuiz,
    resetState,
  }

  return (
    <FinishContext.Provider value={value}>{children}</FinishContext.Provider>
  )
}

const Content = (props: {
  leagueSlug?: string
  quizId?: string
  quizType?: QuizType
}) => {
  const slug = props.leagueSlug
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  const viewerQuery = useViewerQuery({ skip: !authenticated })
  const _isAdmin = viewerQuery.data?.viewer?.isAdmin

  const leagueQuery = useLeaguePageQuery(
    props.leagueSlug ?? '',
    slug === undefined || slug === null || slug === '' // skip if not defined
  )

  const viewerCanEdit =
    leagueQuery.data?.league?.viewerIsOwner || _isAdmin || false

  useEffect(() => {
    if (_isAdmin) {
      setIsAdmin(_isAdmin)
    }
  }, [_isAdmin])

  const [isAdmin, setIsAdmin] = useState(_isAdmin ?? false)

  const [showTemplateModal, setShowTemplateModal] = useState(true)
  const [step, setStep] = useState<Step>(Step.None)
  const [_AIPrompt, setAIPrompt] = useState('')
  const [facts, setFacts] = useState([])
  const [url, setUrl] = useState('')
  const [factIndex, setFactIndex] = useState(0)

  const [questions, setQuestions] = useState<(Question | CareerPathQuestion)[]>(
    []
  )
  const [editQuestionIndex, setEditQuestionIndex] = useState(-1)
  // const { data, loading } = useCheckSlugAccess(props.leagueSlug ?? '')
  // const clubAccess = data?.checkSlugAccess.find((club) =>
  //   hasOneOfClubRoles(club.viewerRole, 'admin')
  // )

  const [finishedOverview, setFinishedOverview] = useState(false)
  const showSimpleFinish = !props.leagueSlug || finishedOverview

  const [hasRecentlyLoaded, setHasRecentlyLoaded] = useState(false)

  const [IsCareerPath, setIsCareerPath] = useState(false)

  const currentVersion = 'V1' //Bump this whenever new fields or changes to fields are introduced

  const isEditing = props.quizId !== undefined

  const [quizType, setQuizType] = useState<QuizType>(
    props.quizType ?? QuizType.COMBINATION
  )

  const {
    setId,
    quizName,
    setQuizName,
    promotionalText,
    setPromotionalText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    language,
    setLanguage,
    noExpirationDate,
    setNoExpirationDate,
    randomizeOrder,
    setRandomizeOrder,
    selectedCategories,
    setSelectedCategories,
    load,
    setLoad,
    setLoadStartState,
  } = useFinishContext()

  useEffect(() => {
    if (isEditing || !props.leagueSlug) return
    const oldDataJSON = window.localStorage.getItem(props.leagueSlug)
    const oldDataVersion = window.localStorage.getItem(
      props.leagueSlug + '|version'
    )
    if (oldDataVersion !== currentVersion) return
    if (!oldDataJSON) return
    const {
      quizName,
      promotionalText,
      startDate,
      endDate,
      language,
      noExpirationDate,
      randomizeOrder,
      selectedCategories,
      questions,
    } = JSON.parse(oldDataJSON)

    setQuizName(quizName)
    setPromotionalText(promotionalText)
    setStartDate(startDate ? dayjs(new Date(startDate)) : null)
    setEndDate(endDate ? dayjs(new Date(endDate)) : null)
    setLanguage(language)
    setNoExpirationDate(noExpirationDate)
    setRandomizeOrder(randomizeOrder)
    setSelectedCategories(selectedCategories)
    setQuestions(questions)
  }, [])

  const jsonData = () => {
    return JSON.stringify({
      quizName,
      promotionalText,
      startDate: startDate,
      endDate: endDate,
      language: language,
      noExpirationDate,
      randomizeOrder,
      selectedCategories,
      questions: questions,
    })
  }

  useEffect(() => {
    if (isEditing || !props.leagueSlug) return
    window.localStorage.setItem(props.leagueSlug, jsonData())
    window.localStorage.setItem(props.leagueSlug + '|version', currentVersion)
  }, [
    questions,
    quizName,
    promotionalText,
    startDate,
    endDate,
    language,
    noExpirationDate,
    randomizeOrder,
    selectedCategories,
  ])

  const quizQuery = useGetQuizQuery(
    {
      leagueSlug: props.leagueSlug ?? '',
      quizid: props.quizId ?? '',
    },
    !props.leagueSlug || !props.quizId,
    quizType.toString()
  )

  useEffect(() => {
    if (!quizQuery.data) return
    if (
      quizType === QuizType.CAREERPATH &&
      'getCareerPathQuiz' in quizQuery.data
    ) {
      if (!quizQuery.data?.getCareerPathQuiz) {
        console.log('No quiz data')
        return
      }
      const data = quizQuery.data.getCareerPathQuiz
      setLoad(data)
      loadData(data)
    } else if (quizType === QuizType.TEXT && 'getQuiz' in quizQuery.data) {
      if (!quizQuery.data?.getQuiz) {
        console.log('No quiz data')
        return
      }
      const data = quizQuery.data.getQuiz
      setLoad(data)
      loadData(data)
    }
  }, [quizQuery.data])

  useEffect(() => {
    if (!language || !load) return
    loadData({
      ...load,
      language: language,
    } as GetQuizMsg)
  }, [language])

  useEffect(() => {
    setHasRecentlyLoaded(false)
    setLoadStartState(jsonData())
  }, [hasRecentlyLoaded])

  const loadData = (overrideData?: GetQuizMsg | GetCareerPathQuizMsg) => {
    const data = overrideData ?? load
    if (!data) return
    const {
      id,
      title,
      promotionalText,
      randomizeOrder,
      startDate,
      endDate,
      questions,
    } = data

    const tags = 'tags' in data ? data.tags : []
    const language = 'language' in data ? data.language : Locale.en

    const lang = language as Locale
    setId(id)
    setQuizName(title[lang] ?? '')
    setLanguage(lang)
    setPromotionalText(promotionalText ? promotionalText[lang] ?? '' : '')
    setRandomizeOrder(randomizeOrder)
    setStartDate(startDate ? dayjs(new Date(startDate)) : null)
    setSelectedCategories(tags ? tags : [])
    setEndDate(endDate ? dayjs(new Date(endDate)) : null)
    setNoExpirationDate(endDate === null)
    setQuestions(
      questions.map((it: QuestionTranslate | CareerPathQuestion) => {
        if ('careerPathPlayer' in it) {
          return it
        }
        const alternativesSorted = swapIndex(
          it.alternatives,
          0,
          it.solutionIndex
        )
        const question: Question = {
          id: it.id,
          question: it.question[language as Locale] ?? '',
          alternatives: [...Array(4).keys()].map((n) =>
            it.alternatives.length > n
              ? alternativesSorted[n][language] ?? ''
              : ''
          ),
          timeless: it.timeless,
          // We can just ignore these ai-related stuff for a user generated quiz
          aiGenerated: false,
          referenceUrl: '',
          questionIndex: 0,
          questionLimit: 0,
          madeByUser: false,
          leagueSlug: '',
        }
        return question
      }) ?? []
    )
    setHasRecentlyLoaded(true)
  }

  useEffect(() => {
    if (props.leagueSlug) {
      setShowTemplateModal(false)
      setStep(Step.Finish)
    }
  }, [])

  // When editQuestionIndex is set, update IsCareerPath if the question is a CareerPathQuestion
  useEffect(() => {
    if (editQuestionIndex !== -1) {
      const question = questions[editQuestionIndex]
      if (question && 'careerPathPlayer' in question) {
        setIsCareerPath(true)
      } else {
        setIsCareerPath(false)
      }
    }
  }, [editQuestionIndex])

  // When a question is added, update quizType
  useEffect(() => {
    if (questions.length > 0) {
      let hasCareerPath = false
      let hasText = false
      questions.forEach((question) => {
        if ('careerPathPlayer' in question) {
          hasCareerPath = true
        } else {
          hasText = true
        }
      })
      if (hasCareerPath && hasText) {
        setQuizType(QuizType.COMBINATION)
      } else if (hasCareerPath) {
        setQuizType(QuizType.CAREERPATH)
      } else {
        setQuizType(QuizType.TEXT)
      }
    }
  }, [questions])

  const hasAccess = props.leagueSlug && viewerCanEdit
  if (!hasAccess) {
    if (props.leagueSlug && leagueQuery.loading) {
      return (
        <Flex horizontal="center">
          <Loader />
        </Flex>
      )
    }
    return (
      <>
        <Heading level={2}>Forbidden</Heading>
        <Heading level={5} strong={false}>
          You don't have permission to access this resource.
        </Heading>
      </>
    )
  }
  return (
    <>
      {showTemplateModal && (
        <PickTemplateModal
          leagueSlug={props.leagueSlug}
          setTemplate={(template: 'ai' | 'blank' | 'career-path') => {
            setStep(template === 'ai' ? Step.AIPrompt : Step.Questions)
            if (template === 'career-path') {
              setIsCareerPath(true)
            } else {
              setIsCareerPath(false)
            }
          }}
          onClose={(forced: boolean = true) => {
            if (!forced) {
              setShowTemplateModal(false)
            }
          }}
          quizType={quizType}
        />
      )}

      {step === Step.AIPrompt && (
        <AIPrompt
          setAIPrompt={(arg: any) => {
            setAIPrompt(arg)
            setStep(Step.AIVerify)
          }}
          setFacts={(arg: any) => {
            setFacts(arg)
            setStep(Step.Questions)
          }}
          goBack={() => {
            setStep(Step.None)
            setShowTemplateModal(true)
          }}
        />
      )}
      {step === Step.AIVerify && (
        <AIVerify
          AIPrompt={_AIPrompt}
          facts={facts}
          setFacts={setFacts}
          setUrl={setUrl}
          start={() => {
            setStep(Step.Questions)
          }}
          setFactIndex={setFactIndex}
          useUserUI={props.leagueSlug !== undefined && !isAdmin}
        />
      )}
      {step === Step.Questions && (
        <GenerateQuestion
          text={facts.length > factIndex ? facts[factIndex] : ''}
          url={url}
          questions={questions}
          setQuestions={setQuestions}
          factIndex={factIndex}
          factIndexMax={facts.length}
          nextText={() => {
            const questionsLength =
              questions.length + (editQuestionIndex == -1 ? 1 : 0)
            // One more question is added as of right now
            setEditQuestionIndex(-1)
            const moreFactsRemaining =
              facts.length == 0 || factIndex + 1 < facts.length
            const noQuestionsRemaining =
              props.leagueSlug && questionsLength >= 8
            if (moreFactsRemaining && !noQuestionsRemaining) {
              setFactIndex(factIndex + 1)
            } else {
              setStep(Step.Finish)
            }
          }}
          finish={() => {
            setStep(Step.Finish)
          }}
          // useUserUI={props.leagueSlug !== undefined && !isAdmin}
          useUserUI={true}
          leagueSlug={props.leagueSlug ?? ''}
          editQuestionIndex={editQuestionIndex}
          careerPath={IsCareerPath}
        />
      )}

      {step === Step.Finish &&
        (showSimpleFinish ? (
          <Finish leagueSlug={props.leagueSlug ?? ''} />
        ) : (
          <OverviewFinish
            setQuestions={setQuestions}
            questions={questions}
            resetQuestions={() => setQuestions([])}
            leagueSlug={props.leagueSlug ?? ''}
            addMoreQuestions={(edit?: boolean) => {
              if (isAdmin && !edit) {
                setStep(Step.None)
                setShowTemplateModal(true)
              } else {
                setStep(Step.Questions)
              }

              setFactIndex(0)
              setFacts([])
              setAIPrompt('')
              setUrl('')
            }}
            showIsAdmin={_isAdmin ?? false}
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            setEditQuestionIndex={setEditQuestionIndex}
            setFinishedOverview={setFinishedOverview}
            quizId={props.quizId}
            jsonData={jsonData}
            isEditing={isEditing}
          />
        ))}
    </>
  )
}

export const GeneratePage = (props: {
  leagueSlug?: string
  quizId?: string
  quizType?: string
}) => {
  // Convert quizType to QuizType
  let quizType: QuizType | undefined
  if (props.quizType) {
    quizType = props.quizType as QuizType
  }

  return (
    <Layout backgroundColor={colors.grey100}>
      <Header color="grey" />
      <Page dense>
        <FinishContextProvider>
          <Content
            leagueSlug={props.leagueSlug}
            quizId={props.quizId}
            quizType={quizType}
          />
        </FinishContextProvider>
      </Page>
    </Layout>
  )
}
