/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Flex } from '../../components/layout/Flex'
import { ModalTrigger } from '../../components/modal/Modal'
import { SelectInput } from '../../components/SelectInput'
import { TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors, hexOpacity } from '../../styles/theme'
import {
  AnalyticsFilter,
  AnalyticsParameter,
  AnalyticsTimeRange,
} from './AdminAnalyticsPage'
import { EditAnalyticslabelModal } from './EditAnalyticsLabelModal'
import { AnalyticsLabel, EventDataset } from './queries'

function getDateWeek(date: Date): number {
  const currentDate = new Date(date.getTime())

  // Set the date to the nearest Thursday (current date + 4 - current day number)
  // because the ISO week date system uses weeks that start on Monday
  currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7))

  // January 1st of the year
  const yearStart = new Date(currentDate.getFullYear(), 0, 1)

  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(
    ((currentDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  )

  return weekNo
}

export const LineChartV2 = ({
  datasets,
  timeRange,
  groupBy,
  setGroupBy,
  analyticsLabels,
  editable = true,
  filterBy,
  setFilterBy,
}: {
  datasets: EventDataset[]
  timeRange: AnalyticsTimeRange
  groupBy: AnalyticsParameter
  setGroupBy: (groupBy: AnalyticsParameter) => void
  analyticsLabels: AnalyticsLabel[]
  editable?: boolean
  filterBy: AnalyticsFilter[]
  setFilterBy?: (filterBy: AnalyticsFilter[]) => void
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

  const [scaling, setScaling] = useState('daily')

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        fullSize: true,
      },
    },
  }

  const months: { [key: number]: string } = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  }

  const colorByIndex = (index: number) => {
    const lineColors = [
      colors.blue400,
      colors.green400,
      colors.red400,
      colors.yellow400,
      colors.purple400,
      colors.pink400,
      colors.green500,
      colors.red500,
      colors.yellow500,
      colors.purple500,
      colors.pink500,
      colors.orange400,
      colors.dailyYellow,
      colors.grey300,
    ]

    return lineColors[index % lineColors.length]
  }

  const findCustomLabelForEvents = (id: string) => {
    const label = analyticsLabels.find((label) => label.id === id)

    if (label) {
      return label.label
    }

    return id
  }

  const aggregateData = (scaling: string, data: EventDataset[]) => {
    const aggregatedData = data.map((dataset) => {
      const result: { [key: string]: number } = {}

      dataset.data.forEach((value, index) => {
        const date = new Date(timeRange.startDate)
        date.setDate(date.getDate() + index)

        let key: string
        switch (scaling) {
          case 'weekly':
            const week = getDateWeek(date).toString()
            key = `${date.getFullYear()}-${week}`
            break
          case 'monthly':
            key = `${date.getFullYear()}-${date.getMonth()}`
            break
          case 'yearly':
            key = `${date.getFullYear()}`
            break
          case 'daily':
            key = date.toISOString().split('T')[0]
            break
          default:
            key = date.toISOString().split('T')[0]
            break
        }

        if (!result[key]) {
          result[key] = 0
        }
        result[key] += value || 0
      })

      return { label: dataset.label, data: Object.values(result) }
    })

    const labels = Object.keys(
      aggregatedData[0] ? aggregatedData[0].data : []
    ).map((key) => {
      const date = new Date(timeRange.startDate.getTime())

      switch (scaling) {
        case 'weekly':
          date.setDate(date.getDate() + parseInt(key) * 7)
          const week = getDateWeek(date)
          return `Week ${week}, ${date.getFullYear()}`
        case 'monthly':
          date.setMonth(date.getMonth() + parseInt(key))
          return `${months[date.getMonth()]} ${date.getFullYear()}`
        case 'yearly':
          date.setFullYear(date.getFullYear() + parseInt(key))
          return `${date.getFullYear()}`
        case 'daily':
          date.setDate(date.getDate() + parseInt(key))
          return ` ${date.getDate()}.${
            months[date.getMonth()]
          } ${date.getFullYear()}`
        default:
          date.setDate(date.getDate() + parseInt(key))
          return `${months[date.getMonth()]} ${date.getDate()}`
      }
    })

    return { labels, datasets: aggregatedData }
  }

  const { labels, datasets: scaledDatasets } = aggregateData(scaling, datasets)

  const isMobileScreen = window.innerWidth < 768
  const topDatasets = scaledDatasets.slice(0, isMobileScreen ? 7 : 12)
  const data = {
    labels: labels.map((label) => label),
    datasets: topDatasets.map((dataSet, index) => {
      return {
        label: findCustomLabelForEvents(dataSet.label),
        data: dataSet.data,
        borderColor: colorByIndex(index),
        backgroundColor: colorByIndex(index),
        borderWidth: 2,
      }
    }),
  }

  return (
    <Flex
      column
      grow
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          padding: 24px 8px;
          background-color: ${colors.grey100};
          border-radius: 10px;
          @media (max-width: 768px) {
            margin: 8px;
            padding: 12px 2px 24px 2px;
            border-radius: 4px;
          }
        `}
      >
        <Flex
          horizontal="flex-start"
          vertical="center"
          css={css`
            padding: 0 0 16px 8px;
          `}
        >
          <SelectInput value={scaling} onValue={(value) => setScaling(value)}>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </SelectInput>
        </Flex>

        {data && (
          <Line
            data={data}
            options={options}
            height={isMobileScreen ? 350 : undefined}
          />
        )}
      </div>

      <Spacer height="large" />
      {editable && (
        <>
          <SelectInput
            value={groupBy}
            onValue={(value) => setGroupBy(value as AnalyticsParameter)}
            label="Group by"
          >
            <option value="none">None</option>
            <option value="clubId">Club</option>
            <option value="leagueId">Quiz Series (prev league)</option>
            <option value="quizId">Quiz</option>
            <option value="clubLeagueSubscriptionId">
              Club League Subscription
            </option>
            <option value="campaignId">Campaign</option>
            <option value="embedDomain">Embed Domain</option>
            <option value="locale">Language</option>
            <option value="type">Type</option>
            <option value="isEmbed">isEmbed</option>
            <option value="isLive">isLive</option>
            <option value="isChallenge">isChallenge</option>
            <option value="isCareerPath">Careerpath</option>
            <option value="leagueQuizId">League Quiz ID</option>
            <option value="trainingQuizId">Training Quiz ID</option>
            <option value="isCareerPath">isCareerPath</option>
            <option value="isNativeApp">isNativeApp</option>
          </SelectInput>
          <Spacer height="large" />
          <FilterComponent filterBy={filterBy} setFilterBy={setFilterBy} />
        </>
      )}
      <Spacer height="large" />

      <Flex
        column
        css={css`
          background-color: ${colors.grey100};
          border-radius: 10px;
          padding: 8px;
        `}
      >
        <Flex
          horizontal="space-between"
          css={css`
            padding: 8px;
          `}
        >
          <TextNew size={'huge'}>Total events:</TextNew>
          <TextNew size={'huge'} strong>
            {datasets.reduce((acc, dataset) => acc + dataset.total, 0)}
          </TextNew>
        </Flex>
        <Spacer height="small" />
        <Flex column>
          {datasets.map((dataSet, index) => {
            return (
              <ModalTrigger
                key={index}
                button={({ openModal }) => (
                  <Flex
                    onClick={editable ? openModal : undefined}
                    horizontal="space-between"
                    vertical="center"
                    css={css`
                      background-color: ${hexOpacity(colorByIndex(index), 0.2)};
                      padding: 8px 12px 8px 8px;
                    `}
                  >
                    <Flex gap={'tiny'} vertical="center">
                      <div
                        css={css`
                          height: 8px;
                          width: 8px;
                          aspect-ratio: 1;
                          border-radius: 50%;
                          background-color: ${colorByIndex(index)};
                        `}
                      />
                      <TextNew>
                        {findCustomLabelForEvents(dataSet.label)}
                      </TextNew>
                    </Flex>
                    <TextNew>{dataSet.total}</TextNew>
                  </Flex>
                )}
                modal={({ closeModal }) => (
                  <EditAnalyticslabelModal
                    closeModal={closeModal}
                    eventId={dataSet.label}
                    label={findCustomLabelForEvents(dataSet.label)}
                  />
                )}
              />
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}

const FilterComponent = ({
  filterBy,
  setFilterBy,
}: {
  filterBy: AnalyticsFilter[]
  setFilterBy?: (value: AnalyticsFilter[]) => void
}) => {
  const t = useT()
  const [editMode, setEditMode] = useState(false)
  const [tempFilterByParameter, setTempFilterByParameter] =
    useState<AnalyticsParameter>('campaignId')

  const boolParameters = [
    'isLive',
    'isEmbed',
    'isChallenge',
    'isCareerPath',
    'isNativeApp',
  ]

  const isBoolParameter = boolParameters.includes(tempFilterByParameter)

  const [tempFilterValue, setTempFilterValue] = useState<string | undefined>(
    isBoolParameter ? 'true' : undefined
  )

  useEffect(() => {
    if (isBoolParameter) {
      setTempFilterValue('true')
    } else {
      setTempFilterValue('')
    }
  }, [tempFilterByParameter])

  if (!setFilterBy) {
    return null
  }

  return (
    <Flex column gap={'small'}>
      <TextNew>Filter by</TextNew>
      {filterBy.map((filter, index) => (
        <Flex
          horizontal="space-between"
          gap={'small'}
          vertical="center"
          key={`filter_${index}`}
        >
          <TextNew>{`${filter.parameter}: ${filter?.value}`}</TextNew>
          <SecondaryButton
            onClick={() => setFilterBy(filterBy.filter((f) => f !== filter))}
          >
            {t('Remove')}
          </SecondaryButton>
        </Flex>
      ))}
      {!editMode && (
        <SecondaryButton onClick={() => setEditMode(true)}>
          Add filter
        </SecondaryButton>
      )}
      {editMode && (
        <Flex
          column
          gap={'small'}
          css={css`
            background-color: ${colors.grey100};
            border-radius: 10px;
            padding: 8px;
          `}
        >
          <TextNew>Filter by</TextNew>
          <Flex horizontal="space-between" gap={'small'} vertical="center">
            <SelectInput
              value={tempFilterByParameter}
              onValue={(value) =>
                setTempFilterByParameter(value as AnalyticsParameter)
              }
            >
              <option value="clubId">Club</option>
              <option value="leagueId">Quiz Series (prev league)</option>
              <option value="quizId">Quiz</option>
              <option value="clubLeagueSubscriptionId">
                Club League Subscription
              </option>
              <option value="campaignId">Campaign</option>
              <option value="embedDomain">Embed Domain</option>
              <option value="locale">Language</option>
              <option value="type">Type</option>
              <option value="isEmbed">isEmbed</option>
              <option value="isLive">isLive</option>
              <option value="isChallenge">isChallenge</option>
              <option value="isCareerPath">Careerpath</option>
              <option value="leagueQuizId">League Quiz ID</option>
              <option value="trainingQuizId">Training Quiz ID</option>
              <option value="isCareerPath">isCareerPath</option>
              <option value="isNativeApp">isNativeApp</option>
            </SelectInput>
            {isBoolParameter ? (
              <Flex gap={'small'} vertical="center">
                <input
                  type="radio"
                  value="true"
                  checked={tempFilterValue === 'true'}
                  onChange={() => {
                    setTempFilterValue('true')
                  }}
                  css={css`
                    height: 32px;
                    width: 32px;
                  `}
                />
                <TextNew>True</TextNew>
                <input
                  type="radio"
                  value="false"
                  checked={tempFilterValue === 'false'}
                  onChange={() => {
                    setTempFilterValue('false')
                  }}
                  css={css`
                    height: 32px;
                    width: 32px;
                  `}
                />
                <TextNew>False</TextNew>
              </Flex>
            ) : (
              <TextInput
                value={tempFilterValue as string}
                placeholder={tempFilterByParameter}
                onValue={(value) => {
                  setTempFilterValue(value)
                }}
                css={css`
                  flex: 1;
                `}
              />
            )}
          </Flex>
          <Spacer height="small" />
          <Flex gap={'small'}>
            <PrimaryButton
              disabled={tempFilterValue === undefined || tempFilterValue === ''}
              css={css`
                flex: 1;
              `}
              onClick={() => {
                setFilterBy([
                  ...filterBy,
                  {
                    parameter: tempFilterByParameter,
                    value: tempFilterValue,
                  },
                ])
                setEditMode(false)
                setTempFilterByParameter('campaignId')
                setTempFilterValue(undefined)
              }}
            >
              {t('Save')}
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                setEditMode(false)
                setTempFilterByParameter('campaignId')
                setTempFilterValue(undefined)
              }}
            >
              {t('Cancel')}
            </SecondaryButton>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
