import { gql, useMutation, useQuery } from '@apollo/client'
import { uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MaskShape } from '../../lib/imageMask'
import { HomePageLeague, homePageLeagueFragment } from '../Home/queries'
import { LeaderboardItem, leaderboardItemFragment } from '../League/queries'

export type ClubRole = 'admin' | 'moderator' | 'senior' | 'member'
export const clubRoles = ['admin', 'moderator', 'senior', 'member']

export type Club = {
  id: string
  slug: string
  name: string
  description: string
  invitationCode?: string | null
  public: boolean
  verified: boolean
  logoUrl: string | null
  logoShape: MaskShape
  backgroundImageUrl: string | null
  membersCount: number
  viewerIsClubAdmin: boolean
  viewerIsMember: boolean
  viewerRole: ClubRole | null
  viewerMainClub: boolean
  externalSiteUrl: string | null
}

export type ClubWithMembers = Club & {
  members: Array<{
    id: string
    role: ClubRole
    user: {
      id: string
      username: string
      slug: string
      avatarData?: string
      displayName?: string
    }
  }>
}

export const clubFragment = gql`
  fragment Club on Club {
    id
    slug
    name
    description
    invitationCode
    logoUrl
    logoShape
    backgroundImageUrl
    verified
    public
    membersCount
    viewerIsClubAdmin
    viewerIsMember
    viewerRole
    viewerMainClub
    externalSiteUrl
  }
`

export const clubWithMembersFragment = gql`
  fragment ClubWithMembers on Club {
    ...Club

    members {
      id
      role
      user {
        id
        username
        slug
        avatarData
        displayName
      }
    }
  }

  ${clubFragment}
`

export const useClubWithMembersQuery = (slug: string) => {
  return useQuery<{ club: ClubWithMembers }>(
    gql`
      query clubWithMembers($slug: String!) {
        club(slug: $slug) {
          ...ClubWithMembers
        }
      }

      ${clubWithMembersFragment}
    `,
    {
      variables: { slug },
      fetchPolicy: 'cache-and-network',
    }
  )
}

const joinClubMutation = gql`
  mutation joinClub($input: JoinClub!) {
    joinClub(input: $input) {
      ...Club
    }
  }

  ${clubFragment}
`

export const useClubsQuery = (
  options: {
    limit?: number
    query?: string
    skip?: boolean
    verifiedOnly?: boolean
    skipCacheRead?: boolean
  } = { limit: 100, verifiedOnly: false }
) => {
  const {
    data,
    loading,
    fetchMore: fetchMoreReal,
    refetch,
  } = useQuery<
    { clubs: Club[] },
    {
      input?: {
        query?: string
        limit?: number
        offset?: number
        verifiedOnly?: boolean
      }
    }
  >(
    gql`
      query clubsQuery($input: ClubsInput) {
        clubs(input: $input) {
          ...Club
        }
      }

      ${clubFragment}
    `,
    {
      variables: {
        input: {
          query: options.query,
          limit: options.limit,
          offset: 0,
          verifiedOnly: options.verifiedOnly,
        },
      },
      skip: options.skip,
      fetchPolicy: options.skipCacheRead ? 'no-cache' : 'cache-and-network',
    }
  )

  const clubs = useMemo(
    () => uniqBy(data?.clubs ?? [], 'id'),
    [data, options.query, options.verifiedOnly]
  )

  const [hasMore, setHasMore] = useState(true)
  useEffect(() => setHasMore(true), [options.query, options.verifiedOnly])
  const fetchMore = useCallback(() => {
    const lastClub: Club | undefined = clubs.slice(-1)[0]

    fetchMoreReal({
      variables: {
        input: {
          query: options.query,
          limit: options.limit,
          offset: clubs.length,
          verifiedOnly: options.verifiedOnly,
        },
      },
    }).then((result) => {
      const lastClubFromResult = result.data?.clubs.slice(-1)[0]

      if (!lastClubFromResult) {
        setHasMore(false)
      } else if (lastClubFromResult.id === lastClub?.id) {
        setHasMore(false)
      }
    })
  }, [clubs, fetchMoreReal, options.query, options.limit, options.verifiedOnly])

  return {
    clubs,
    loading,
    hasMore,
    fetchMore,
    refetch,
  }
}

const viewerClubsQuery = gql`
  query viewerClubsQuery {
    viewer {
      id
      clubMemberships {
        id
        role
        club {
          ...Club
        }
      }
    }
  }

  ${clubFragment}
`
export const useViewerClubsQuery = () => {
  return useQuery<{
    viewer: {
      clubMemberships: Array<{
        id: string
        role: ClubRole
        club: Club
      }>
    }
  }>(viewerClubsQuery, {
    fetchPolicy: 'cache-and-network',
  })
}

export const useJoinClubMutation = () => {
  return useMutation<
    Club,
    { input: { invitationCode?: string; clubId?: string } }
  >(joinClubMutation)
}

const removeFromClubMutation = gql`
  mutation removeFromClub($input: RemoveFromClub!) {
    removeFromClub(input: $input) {
      ...Club
    }
  }

  ${clubFragment}
`

export const useRemoveFromClubMutation = () => {
  return useMutation<Club, { input: { userId: string; clubId: string } }>(
    removeFromClubMutation
  )
}

type CreateClubInput = {
  name: string
}

const createClubMutation = gql`
  mutation createClub($input: CreateClubInput!) {
    createClub(input: $input) {
      ...Club
    }
  }

  ${clubFragment}
`
export const useCreateClubMutation = () => {
  return useMutation<Club, { input: CreateClubInput }>(createClubMutation, {
    refetchQueries: [viewerClubsQuery],
    awaitRefetchQueries: true,
  })
}

const updateInvitationCodeMutation = gql`
  mutation updateInvitationCode($input: UpdateInvitationCode!) {
    updateInvitationCode(input: $input) {
      ...Club
    }
  }

  ${clubFragment}
`
export const useUpdateInvitationCodeMutation = () => {
  return useMutation<Club, { input: { clubId: string } }>(
    updateInvitationCodeMutation
  )
}

const inviteToClubMutation = gql`
  mutation inviteToClub($input: InviteToClub!) {
    inviteToClub(input: $input) {
      ...Club
    }
  }

  ${clubFragment}
`
export const useInviteToClubMutation = () => {
  return useMutation<Club, { input: { clubId: string; email: string } }>(
    inviteToClubMutation
  )
}

export type ClubProfileQueryResult = {
  club: Club & {
    subscribedLeagues: HomePageLeague[]
  }
}

export const clubProfileQuery = gql`
  query clubProfilePage($slug: String!) {
    club(slug: $slug) {
      ...Club
      subscribedLeagues(status: [active, upcoming]) {
        ...HomePageLeagueFragment
        clubLeagueSubscriptionId
      }
    }
  }

  ${clubFragment}
  ${homePageLeagueFragment}
`

export const useClubProfileQuery = (clubSlug: string) => {
  return useQuery<ClubProfileQueryResult>(clubProfileQuery, {
    variables: { slug: clubSlug },
    skip: clubSlug == '',
  })
}

const updateClubMutation = gql`
  mutation updateClub($input: UpdateClub!) {
    updateClub(input: $input) {
      ...Club
    }
  }

  ${clubFragment}
`
export const useUpdateClubMutation = () => {
  return useMutation<
    Club,
    {
      input: {
        clubId: string
        name: string
        description: string
        isPublic: Boolean
        logoUri?: string
        logoShape: MaskShape
        backgroundImageUri?: string
      }
    }
  >(updateClubMutation)
}

export const useUpdateClubMember = () => {
  return useMutation<
    { updateClubMember: Club },
    {
      input: {
        clubId: string
        userId: string
        role: ClubRole
      }
    }
  >(gql`
    mutation updateClubMember($input: UpdateClubMemberInput!) {
      updateClubMember(input: $input) {
        id
        role
        user {
          id
          username
          avatarData
          displayName
        }
      }
    }
  `)
}

export const useTransferClubAdmin = () => {
  return useMutation<unknown, { clubId: string; toUserId: string }>(gql`
    mutation transferClubAdmin($clubId: ID!, $toUserId: ID!) {
      transferClubAdmin(clubId: $clubId, toUserId: $toUserId) {
        ...ClubWithMembers
      }
    }

    ${clubWithMembersFragment}
  `)
}

export const useDeleteClubMutation = () => {
  return useMutation<{ deleteClub: boolean }, { clubId: string }>(gql`
    mutation deleteClub($clubId: ID!) {
      deleteClub(clubId: $clubId)
    }
  `)
}

export const useClubLeaderboard = (slug: string) => {
  return useQuery<
    { club: { leaderboard: LeaderboardItem[] } },
    { slug: string }
  >(
    gql`
      query clubLeaderboard($slug: String!) {
        club(slug: $slug) {
          leaderboard(limit: 50) {
            ...LeaderboardItem
          }
        }
      }

      ${leaderboardItemFragment}
    `,
    { variables: { slug } }
  )
}

export const useGetClubFromClubLeagueSubscriptionId = (
  clubLeagueSubscriptionId?: string
) => {
  return useQuery<{ clubFromSubscriptionId: Club }>(
    gql`
      query clubFromSubscriptionId($clubLeagueSubscriptionId: ID!) {
        clubFromSubscriptionId(
          clubLeagueSubscriptionId: $clubLeagueSubscriptionId
        ) {
          ...Club
        }
      }

      ${clubFragment}
    `,
    {
      variables: { clubLeagueSubscriptionId },
      skip: !clubLeagueSubscriptionId,
    }
  )
}
